<template>
  <div class="main_header_wrapper">
    <div class="main_header flx">
      <div class="left flx">
        <burger-button></burger-button>
        <router-link :to="{ name: 'HomePage' }" class="logo_wrapper flx">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.7309 0.63407L1.13314 5.53801C0.793201 5.65339 0.566572 5.99955 0.566572 6.34572V23.6537C0.566572 24.0287 0.793201 24.346 1.13314 24.4614L14.7309 29.3654C14.8159 29.3942 14.9292 29.4231 15.0142 29.4231C15.0708 29.4231 15.0992 29.4231 15.1558 29.4231H15.1841C15.2125 29.4231 15.2408 29.3942 15.2691 29.3942L28.8669 24.4903C29.2068 24.3749 29.4334 24.0287 29.4334 23.6826V6.31687C29.4334 6.28802 29.4334 6.23033 29.4334 6.20148V6.17264C29.3768 5.88417 29.1785 5.62455 28.8952 5.53801L22.0963 3.08604H22.068C21.898 3.02835 21.6714 2.9995 21.4731 3.08604L8.2153 7.84574C7.87535 7.96113 7.64873 8.30729 7.64873 8.65345V11.8266C7.64873 12.317 8.017 12.692 8.49858 12.692C8.98017 12.692 9.34844 12.317 9.34844 11.8266V9.25923L21.7564 4.78799L26.0623 6.34572L14.7309 10.4419C14.3909 10.5573 14.1643 10.8746 14.1643 11.2497C14.1643 11.2497 14.1643 11.2497 14.1643 11.2785V27.375L2.26629 23.0479V6.9515L15.2975 2.24948C15.7507 2.0764 15.9773 1.58601 15.8074 1.15331C15.6657 0.691764 15.1841 0.46099 14.7309 0.63407ZM15.864 11.8554L27.762 7.55728V23.0191L15.864 27.3173V11.8554Z"
              fill="#F5A32A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.2691 29.3942C15.255 29.3942 15.2408 29.4014 15.2266 29.4086C15.2125 29.4159 15.1983 29.4231 15.1841 29.4231H15.0142C14.9292 29.4231 14.8159 29.3942 14.7309 29.3654L1.13314 24.4614C0.793201 24.346 0.566572 24.0287 0.566572 23.6537V6.34572C0.566572 5.99955 0.793201 5.65339 1.13314 5.53801L14.7309 0.63407C15.1841 0.46099 15.6657 0.691764 15.8074 1.15331C15.9773 1.58601 15.7507 2.0764 15.2975 2.24948L2.26629 6.9515V23.0479L14.1643 27.375V11.2497C14.1643 10.8746 14.3909 10.5573 14.7309 10.4419L26.0623 6.34572L21.7564 4.78799L9.34844 9.25923V11.8266C9.34844 12.317 8.98017 12.692 8.49858 12.692C8.017 12.692 7.64873 12.317 7.64873 11.8266V8.65345C7.64873 8.30729 7.87535 7.96113 8.2153 7.84574L21.4731 3.08604C21.6714 2.9995 21.898 3.02835 22.068 3.08604H22.0963L28.8952 5.53801C29.1785 5.62455 29.3768 5.88417 29.4334 6.17264V23.6826C29.4334 24.0287 29.2068 24.3749 28.8669 24.4903L15.2691 29.3942ZM29.0516 25.0357L15.4313 29.9478C15.4233 29.9513 15.4142 29.955 15.4043 29.9588C15.362 29.9749 15.284 30 15.1841 30H15.0148C14.8365 30 14.6524 29.9469 14.5517 29.9127L14.5417 29.9093L0.948408 25.0069C0.378576 24.8108 0 24.2733 0 23.6537V6.34572C0 5.76632 0.368201 5.19244 0.948291 4.99263L14.5374 0.0917971C15.2737 -0.186016 16.091 0.184819 16.3415 0.959989C16.624 1.71312 16.214 2.51565 15.4964 2.78968L15.4867 2.79338L2.83286 7.35914V22.6414L13.5977 26.5563V11.2507C13.5977 10.6313 13.9762 10.0928 14.5459 9.89662L24.3693 6.34551L21.756 5.40012L9.91501 9.66706V11.8266C9.91501 12.6356 9.29308 13.2689 8.49858 13.2689C7.70409 13.2689 7.08215 12.6356 7.08215 11.8266V8.65345C7.08215 8.07392 7.45053 7.4999 8.03086 7.30022L21.2699 2.54725C21.5922 2.4136 21.9172 2.44617 22.1499 2.50911H22.1937L29.0731 4.99013C29.5812 5.15223 29.8994 5.60318 29.989 6.05949L30 6.11551V23.6826C30 24.262 29.6318 24.8359 29.0516 25.0357ZM16.4306 12.2629V26.5004L27.1955 22.6116V8.37411L16.4306 12.2629ZM15.864 11.8554V27.3173L27.762 23.0191V7.55728L15.864 11.8554Z"
                  fill="#F5A32A"/>
          </svg>
          <span>BrewDrop</span>
        </router-link>
        <header-search></header-search>
        <!--        <nav-menu class="main_navigation" :items="main_navs" :ul-class="'flx'">-->
        <!--          <template v-slot="{ item }">-->
        <!--            <router-link :to="{ name: item.routeName }">{{item.title}}</router-link>-->
        <!--          </template>-->
        <!--        </nav-menu>-->
      </div>
      <div class="right flx">
        <div class="main_icon_wrapper flx">
          <router-link :to="{ name: 'Favorites' }" class="favorites" v-if="isAuth()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.70862 0.837569C7.82748 0.586172 8.01142 0.374488 8.23964 0.226468C8.46786 0.0784478 8.73124 0 8.99998 0C9.26872 0 9.5321 0.0784478 9.76032 0.226468C9.98854 0.374488 10.1725 0.586172 10.2913 0.837569L12.2927 5.06885L16.7671 5.74465C17.033 5.78493 17.2829 5.90207 17.4884 6.08279C17.6938 6.26351 17.8467 6.50061 17.9297 6.76726C18.0126 7.0339 18.0224 7.31944 17.9578 7.59157C17.8933 7.8637 17.757 8.11155 17.5644 8.30707L14.3265 11.5979L15.0914 16.2459C15.1365 16.5218 15.1068 16.8054 15.0056 17.0647C14.9043 17.3239 14.7356 17.5485 14.5185 17.7131C14.3014 17.8776 14.0445 17.9756 13.7769 17.996C13.5092 18.0164 13.2414 17.9583 13.0037 17.8284L8.99908 15.6339L4.9963 17.8284C4.75859 17.9583 4.49078 18.0164 4.22311 17.996C3.95543 17.9756 3.69855 17.8776 3.48144 17.7131C3.26433 17.5485 3.09564 17.3239 2.9944 17.0647C2.89317 16.8054 2.86342 16.5218 2.90852 16.2459L3.67164 11.5979L0.43378 8.30519C0.241663 8.10947 0.105852 7.86162 0.041694 7.58962C-0.0224645 7.31763 -0.0124139 7.03234 0.0707103 6.766C0.153834 6.49965 0.306719 6.26286 0.51209 6.08237C0.717461 5.90189 0.967133 5.78491 1.2329 5.74465L5.70723 5.06697L7.70862 0.837569ZM9.00088 2.35062L7.23707 6.07504C7.13384 6.29284 6.98148 6.48127 6.79308 6.62416C6.60468 6.76704 6.38585 6.86011 6.15538 6.89539L2.21379 7.49235L5.06649 10.3908C5.23361 10.5607 5.3586 10.7705 5.43072 11.0021C5.50283 11.2336 5.5199 11.4799 5.48045 11.7199L4.80732 15.8122L8.33135 13.8806C8.53786 13.7674 8.76762 13.7083 9.00088 13.7083C9.23414 13.7083 9.4639 13.7674 9.67041 13.8806L13.1944 15.8141L12.5213 11.7217C12.4816 11.4815 12.4985 11.2348 12.5706 11.0029C12.6427 10.771 12.7679 10.5609 12.9353 10.3908L15.7862 7.49235L11.8464 6.89539C11.6156 6.8604 11.3964 6.76746 11.2077 6.62456C11.0189 6.48166 10.8663 6.29308 10.7629 6.07504L9.00088 2.35062Z"
                fill="#ADB8C6"/>
            </svg>
          </router-link>
          <router-link :to="{ name: 'CheckoutPage' }" class="basket active flx" v-if="isAuth()">
            <div class="basket_wrapper">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.2431 18C12.8335 18 13.3997 17.7629 13.8172 17.341C14.2347 16.919 14.4692 16.3467 14.4692 15.75C14.4692 15.1533 14.2347 14.581 13.8172 14.159C13.3997 13.7371 12.8335 13.5 12.2431 13.5C11.6527 13.5 11.0865 13.7371 10.669 14.159C10.2516 14.581 10.017 15.1533 10.017 15.75C10.017 16.3467 10.2516 16.919 10.669 17.341C11.0865 17.7629 11.6527 18 12.2431 18ZM4.45174 18C5.04214 18 5.60836 17.7629 6.02584 17.341C6.44331 16.919 6.67785 16.3467 6.67785 15.75C6.67785 15.1533 6.44331 14.581 6.02584 14.159C5.60836 13.7371 5.04214 13.5 4.45174 13.5C3.86134 13.5 3.29512 13.7371 2.87764 14.159C2.46016 14.581 2.22563 15.1533 2.22563 15.75C2.22563 16.3467 2.46016 16.919 2.87764 17.341C3.29512 17.7629 3.86134 18 4.45174 18ZM18.9638 2.16337C19.2416 2.15432 19.5051 2.03638 19.6985 1.83452C19.8919 1.63265 20 1.36268 20 1.08169C20 0.800698 19.8919 0.530724 19.6985 0.328857C19.5051 0.12699 19.2416 0.00905829 18.9638 0H17.6826C16.6787 0 15.8105 0.70425 15.5923 1.69425L14.1977 8.0415C13.9795 9.0315 13.1113 9.73575 12.1073 9.73575H3.74606L2.14104 3.2445H12.5425C12.8178 3.23181 13.0776 3.11236 13.2679 2.911C13.4583 2.70964 13.5645 2.44186 13.5645 2.16337C13.5645 1.88489 13.4583 1.61711 13.2679 1.41575C13.0776 1.21439 12.8178 1.09494 12.5425 1.08225H2.14104C1.81565 1.08215 1.49453 1.15704 1.20208 1.30121C0.909629 1.44539 0.65355 1.65506 0.453308 1.91428C0.253066 2.17351 0.113933 2.47546 0.0464877 2.7972C-0.02096 3.11893 -0.0149437 3.45197 0.0640762 3.771L1.6691 10.26C1.78481 10.7282 2.05209 11.1439 2.42844 11.4409C2.80478 11.7379 3.26858 11.8992 3.74606 11.8991H12.1073C13.0815 11.8993 14.0266 11.5636 14.7864 10.9475C15.5463 10.3314 16.0756 9.4718 16.2869 8.51062L17.6826 2.16337H18.9638Z"
                  fill="white"/>
              </svg>
              <span class="counter" v-if="cart_counts > 0">{{ cart_counts }}</span>
            </div>
            <span class="basket_sum" v-if="total > 0">{{ total }}грн</span>
          </router-link>
        </div>
        <user-menu
          v-if="isAuth()&&user"
          :wrapper-classes="userWrapperClasses"
          :menu-wrapper-classes="userMenuWrapperClasses"
          :menu-classes="userMenuClasses"
          :user="user"
        ></user-menu>
          <guest-login
            v-if="!user"
            :wrapper-classes="userWrapperClasses"
          ></guest-login>
          <div
            v-if="!user"
            class="user_wrapper flx"
          >
            <router-link :to="{ name: 'Register' }" class="guest-user flx">
              <div class="guest-user--image flx">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.689941 11.831C0.689941 9.87076 2.27902 8.28169 4.23924 8.28169H9.76036C11.7206 8.28169 13.3097 9.87076 13.3097 11.831V13.2113C13.3097 13.6469 12.9565 14 12.5209 14C12.0853 14 11.7322 13.6469 11.7322 13.2113V11.831C11.7322 10.742 10.8494 9.85915 9.76036 9.85915H4.23924C3.15023 9.85915 2.26741 10.742 2.26741 11.831V13.2113C2.26741 13.6469 1.91428 14 1.47867 14C1.04307 14 0.689941 13.6469 0.689941 13.2113V11.831Z"
                        fill="#3C434A"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3.4505 3.5493C3.4505 1.58907 5.03958 0 6.9998 0C8.96002 0 10.5491 1.58907 10.5491 3.5493C10.5491 5.50952 8.96002 7.09859 6.9998 7.09859C5.03958 7.09859 3.4505 5.50952 3.4505 3.5493ZM6.9998 1.57746C5.91079 1.57746 5.02797 2.46028 5.02797 3.5493C5.02797 4.63831 5.91079 5.52113 6.9998 5.52113C8.08881 5.52113 8.97163 4.63831 8.97163 3.5493C8.97163 2.46028 8.08881 1.57746 6.9998 1.57746Z"
                        fill="#3C434A"></path>
                </svg>
              </div>
              <div class="user_info">
                <span class="user_name">Регистрация</span>
              </div>
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BurgerButton from './BurgerButton'
import HeaderSearch from './HeaderSearch'
import UserMenu from './UserMenu'
// import NavMenu from './NavMenu'
import { mapState } from 'vuex'
import GuestLogin from './GuestLogin'

export default {
  name: 'MainHeader',
  inject: ['isAuth'],
  components: {
    BurgerButton,
    HeaderSearch,
    UserMenu,
    //   NavMenu,
    GuestLogin
  },
  data () {
    return {
      userWrapperClasses: [
        'user_wrapper',
        'open_drop',
        'flx'
      ],
      userMenuWrapperClasses: ['menu_drop'],
      userMenuClasses: ['dropdown']
    }
  },
  computed: {
    ...mapState('menu', ['main_navs']),
    ...mapState('auth', ['user']),
    ...mapState('cart', ['cart_counts', 'total'])
  }
}
</script>

<style scoped>

</style>
