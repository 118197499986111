import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'
import PageNotFound from '../views/pages/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "HomePage" */ './../views/pages/HomePage'),
    meta: {
      title: () => {
        return 'Главная'
      }
    }
  },
  {
    path: '/content/:url',
    name: 'ContentPage',
    component: () => import(/* webpackChunkName: "HomePage" */ './../views/pages/ContentPage'),
    meta: {
      title: () => {
        return ''
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "LoginPage" */ './../views/pages/LoginPage'),
    meta: {
      title: () => {
        return 'Вход'
      }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./../views/pages/RegisterPage.vue'),
    meta: {
      title: () => {
        return 'Регистрация'
      }
    }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import(/* webpackChunkName: "CatalogPage" */ './../views/pages/CatalogPage'),
    meta: {
      title: () => {
        return 'Каталог'
      },
      breadcrumbs: () => {
        const crumbs = [
          {
            name: 'Магазин',
            pathName: 'HomePage',
            item_id: null
          }
        ]
        if (store.state.catalog.categories_ids.length === 1) {
        //  TODO add category breadcrumbs
        } else {
          crumbs.push(
            {
              name: 'Все категории',
              pathName: 'Catalog',
              item_id: null
            }
          )
        }
        return crumbs
      }
    }
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import(/* webpackChunkName: "CatalogPage" */ './../views/pages/FavoritesPage'),
    meta: {
      title: () => {
        return 'Избранные товары'
      },
      breadcrumbs: () => {
        const crumbs = [
          {
            name: 'Магазин',
            pathName: 'HomePage',
            item_id: null
          }
        ]
        crumbs.push(
          {
            name: 'Избранные товары',
            pathName: 'Favorites',
            item_id: null
          }
        )
        return crumbs
      }
    }
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: () => import(/* webpackChunkName: "CatalogPage" */ './../views/pages/StocksPage'),
    meta: {
      title: () => {
        return 'Акции'
      },
      breadcrumbs: () => {
        const crumbs = [
          {
            name: 'Магазин',
            pathName: 'HomePage',
            item_id: null
          }
        ]
        crumbs.push(
          {
            name: 'Акции',
            pathName: 'Stocks',
            item_id: null
          }
        )
        return crumbs
      }
    }
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import(/* webpackChunkName: "ProductPage" */ './../views/pages/ProductPage'),
    meta: {
      title: () => {
        return 'Товар'
      },
      breadcrumbs: () => {
        const crumbs = [
          {
            name: 'Магазин',
            pathName: 'HomePage',
            item_id: null
          }
        ]
        // TODO add product breadcrumbs
        return crumbs
      }
    }
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    redirect: { path: '/cabinet/profile' },
    component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/CabinetPage'),
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/ProfilePage'),
        meta: {
          title: () => {
            return 'Профиль'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Профиль',
                pathName: 'Profile',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/OrdersPage'),
        meta: {
          title: () => {
            return 'Мои заказы'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Заказы',
                pathName: 'Orders',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/order/:id',
        name: 'EditOrder',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/EditOrder'),
        meta: {
          title: () => {
            return 'Редактирование заказа'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Заказы',
                pathName: 'Orders',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/PaymentsPage'),
        meta: {
          title: () => {
            return 'Мои выплаты'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Выплаты',
                pathName: 'Payments',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/personal-data',
        name: 'PersonalData',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/PersonalDataPage'),
        meta: {
          title: () => {
            return 'Личные данные'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Личные данные',
                pathName: 'PersonalData',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/HelpPage'),
        meta: {
          title: () => {
            return 'Поддержка'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Поддержка',
                pathName: 'Help',
                item_id: null
              }
            ]
          }
        }
      },
      {
        path: '/upload-yml',
        name: 'UploadYML',
        component: () => import(/* webpackChunkName: "CabinetPage" */ './../views/pages/cabinet/UploadYMLPage'),
        meta: {
          title: () => {
            return 'Выгрузка товара'
          },
          onlyUser: true,
          breadcrumbs: () => {
            return [
              {
                name: 'Магазин',
                pathName: 'HomePage',
                item_id: null
              },
              {
                name: 'Личный кабинет',
                pathName: 'Profile',
                item_id: null
              },
              {
                name: 'Выгрузка товара',
                pathName: 'Help',
                item_id: null
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import(/* webpackChunkName: "CheckoutPage" */ './../views/pages/CheckoutPage'),
    meta: {
      onlyUser: true,
      title: () => {
        return 'Товары в корзине'
      },
      breadcrumbs: () => {
        return [
          {
            name: 'Магазин',
            pathName: 'HomePage',
            item_id: null
          },
          {
            name: 'Корзина',
            pathName: 'CheckoutPage',
            item_id: null
          }
        ]
      }
    }
  },
  { path: '*', component: PageNotFound }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
  // scrollBehavior (to, from, savedPosition) {
  //   return {
  //     // could also be
  //     el: document.getElementById('app'),
  //     // el: '#app',
  //     top: -10
  //   }
  // }
})

router.beforeEach((to, from, next) => {
  store.commit('menu/setActiveMenu', false)
  store.commit('errors/clearErrors')
  window.scrollTo(0, 0)

  if (to.meta.onlyUser) {
    if (store.state.auth.token) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

export default router
